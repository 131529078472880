.cardDashboard1 {
  height: 340px;
  margin: 10px;
}
.cardDashboard2 {
  height: 420px;
  margin: 10px;
}
.cardDashboard3 {
  height: 300px;
  margin: 10px;
  
}

.card-3 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  height: 360px;
}
.card-4 {
  color: #008ecf;
  font-size: 20px;
  text-align: center;
}
.headText {
  box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1);
  margin-top: -10px;
}
.headText22 {
  box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1);
  font-weight: 500;
}
.tab .ant-table-tbody > tr > td {
  padding: 1px 1px !important;
}
.tab .ant-table-thead > tr > th {
  padding: 1px 1px !important;
  font-family: "bosch-Medium";
}

.Online {
  background-color: #78be20;
  border-radius: 10px;
  color: white;
  text-align: center;
  width: 60px;
  margin-left: 30%;
}
.Offline {
  background-color: rgb(234, 71, 88);
  border-radius: 10px;
  color: white;
  text-align: center;
  width: 60px;
  margin-left: 30%;
}
.idle {
  background-color: #d3d3d3;
  border-radius: 10px;
  color: white;
  text-align: center;
  width: 60px;
  margin-left: 30%;
}
.HighSeviority{
  background-color: rgb(234, 71, 88);
  border-radius: 10px;
  color: white;
  text-align: center;
  width: 60px;
}
.MediumSeviority{
  background-color: rgb(202, 202, 87);
  border-radius: 10px;
  color: white;
  text-align: center;
  width: 60px;
}
.LowSeviority{
  background-color: rgb(88, 207, 100);
  border-radius: 10px;
  color: white;
  text-align: center;
  width: 60px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab1 .ant-table-tbody > tr > td {
  padding: 6px 3px !important;
}
.tab1 .ant-table-thead > tr > th {
  padding: 5px 5px !important;
}
.table-background {
  color: black;
  /* text-align: center; */
}

.tableStyle .table,
th,
td {
  border: 1px solid white;
  border-collapse: collapse;
}

.tableStyle tr:nth-child(even) {
  background-color: #caa7e4;
}
.tableStyle tr:nth-child(odd) {
  background-color: #c3d2e0;
}

.table-row-light {
  background-color: #f5f4f5e7;
}
.table-row-dark {
  background-color: #dde6ed;
}

h4 {
  font-family: "bosch-Bold";
}

/* canvas .barChartHeight   {
  height: 200px !important;
} */

/* ============================================================= */
.formInputDashboardfilter {
  border-bottom: 2px solid #a0a1a1;
  background-color: #bfc0c2;
}
.leaflet-touch .leaflet-bar a {
  display: none !important;
}

.leaflet-container a {
  display: none !important;
}

.searchInput {
  /* padding: 10px; */
  font-size: 14px;
  border: 1px solid grey;
  float: left;
  /* height: 5vh;
  width: 50%; */
  background: #f1f1f1;
  /* margin-left: 200px; */
}
.utilizedStyle .ant-card-body {
  padding: 10px 8px;
}

.srchBtn {
  height: 5vh;
}

.ant-table-pagination.ant-pagination {
  margin: 4px 0;
}

.utilizedTable .ant-table {
  height: 265px;
  overflow-y: auto;
}

.MainTablle .ant-table-tbody > tr > td {
  padding: 2px;
}
.MainTablle tr:nth-child(odd) {
  background: #ffffff;
}
.MainTablle tr:nth-child(even) {
  background: #e8e8e8;
}
.MainTablle .ant-table-thead > tr > th {
  background: #d3d3d3;
  padding: 10px;
}

.leaflet-popup-content {
  cursor: pointer;
}
.utilizationButtonStyle{
  font-size: 33px;
  color: #008ecf;
}
.utilizationButtonNormalStyle{
  font-size: 30px;
  
}
