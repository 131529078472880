
.ant-menu-horizontal > .ant-menu-item a {
  font-size: 16px;
  font-weight: 600;
}
.ant-menu-horizontal {
  line-height: 37px;
}
.site-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  background-color: #333;
  color: #fff;
  text-align: center;
}

.site-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: #333;
  color: #fff;
  text-align: center;
}
.ParameterTableColumn{
overflow-y: auto;
background-color: white;
margin-top: 2px;
}
.ParameterTableColumn1{
  overflow-y: auto;
  background-color: white;
  margin-top: 2px;
  }
.MainLayoutCss{
height: 61vh;
}
  /* 25 screen */
 @media (min-height: 1787px) and (max-height:2382px) {
  .MainLayoutCss{
   height: 89vh !important;
  }
  .chartDiv{
    height: 76vh;
    overflow-y: auto;
  }
  .ParameterTableColumn{
    height: 93vh;
  }
  .ParameterTableColumn1{
    height: 85.3vh;
  }
  .RTMoptionalPerraTab{
    height: 52vh;
  }
  .ReportsdataDiv{
    height: 76vh;
  }
 }
  /* 33 screen */
  @media (min-height: 1701px) and (max-height:1787px) {
    .MainLayoutCss{
     height: 89vh !important;
    }
    .chartDiv{
      height: 75vh;
      overflow-y: auto;
    }
    .ParameterTableColumn{
      height: 92vh;
    }
    .ParameterTableColumn1{
      height: 84.3vh;
    }
    .RTMoptionalPerraTab{
      height: 52vh;
    }
    .ReportsdataDiv{
      height: 75vh;
    }
   }
  @media (min-height: 1601px) and (max-height:1700px) {
    .MainLayoutCss{
     height: 88vh !important;
    }
    .chartDiv{
      height: 74vh;
      overflow-y: auto;
    }
    .ParameterTableColumn{
      height: 91vh;
    }
    .ParameterTableColumn1{
      height: 83.3vh;
    }
    .RTMoptionalPerraTab{
      height: 52vh;
    }
    .ReportsdataDiv{
      height: 74vh;
    }
   }
  @media (min-height: 1501px) and (max-height:1600px) {
    .MainLayoutCss{
     height: 87vh !important;
    }
    .chartDiv{
      height: 73vh;
      overflow-y: auto;
    }
    .ParameterTableColumn{
      height: 90vh;
    }
    .ParameterTableColumn1{
      height: 82.3vh;
    }
    .RTMoptionalPerraTab{
      height: 52vh;
    }
    .ReportsdataDiv{
      height: 73vh;
    }
    .RTMcontainer{
      height: 88.8vh;
    }
   }
  @media (min-height: 1401px) and (max-height:1500px) {
    .MainLayoutCss{
     height: 86vh !important;
    }
    .chartDiv{
      height: 72vh;
      overflow-y: auto;
    }
    .ParameterTableColumn{
      height: 89vh;
    }
    .ParameterTableColumn1{
      height: 81.3vh;
    }
    .RTMoptionalPerraTab{
      height: 52vh;
    }
    .ReportsdataDiv{
      height: 77vh;
    }
    .RTMcontainer{
      height: 87.5vh;
    }
   }
  @media (min-height: 1301px) and (max-height:1400px) {
    .MainLayoutCss{
     height: 85vh !important;
    }
    .chartDiv{
      height: 71vh;
      overflow-y: auto;
    }
    .ParameterTableColumn{
      height: 88vh;
    }
    .ParameterTableColumn1{
      height: 80.3vh;
    }
    .RTMoptionalPerraTab{
      height: 52vh;
    }
    .ReportsdataDiv{
      height: 75vh;
    }
    .RTMcontainer{
      height: 86.5vh;
    }
   }
  @media (min-height: 1191px) and (max-height:1300px) {
  .MainLayoutCss{
   height: 84vh !important;
  }
  .chartDiv{
    height: 70vh;
    overflow-y: auto;
  }
  .ParameterTableColumn{
    height: 85vh;
  }
  .ParameterTableColumn1{
    height: 77.3vh;
  }
  .RTMoptionalPerraTab{
    height: 52vh;
  }
  .ReportsdataDiv{
    height: 73vh;
  }
  .RTMcontainer{
    height: 85.5vh;
  }
  .rtmChartcontainer{
    height: 80.5vh;
  }
   }
/* 50 screen */
  @media (min-height: 1060px) and (max-height:1191px) {
  .MainLayoutCss{
   height: 83vh !important;
  }
  .chartDiv{
    height: 67.5vh;
    overflow-y: auto;
  }
  .ParameterTableColumn{
    height: 82vh;
  }
  .ParameterTableColumn1{
    height: 74.3vh;
  }
  .RTMoptionalPerraTab{
    height: 52vh;
  }
  .ReportsdataDiv{
    height: 72.5vh;
  }
  .RTMcontainer{
    height: 84.5vh;
  }
  .rtmChartcontainer{
    height: 79.5vh;
  }
   }
  @media (min-height: 1010px) and (max-height:1060px) {
  .MainLayoutCss{
   height: 82vh !important;
  }
  .chartDiv{
    height: 66.6vh;
    overflow-y: auto;
  }
  .ParameterTableColumn{
    height: 79vh;
  }
  .ParameterTableColumn1{
    height: 72.3vh;
  }
  .RTMoptionalPerraTab{
    height: 52vh;
  }
  .ReportsdataDiv{
    height: 71.5vh;
  }
  .RTMcontainer{
    height: 83.5vh;
  }
  .rtmChartcontainer{
    height: 78.5vh;
  }
   }
  @media (min-height: 951px) and (max-height:1010px) {
  .MainLayoutCss{
   height: 81vh !important;
  }
  .chartDiv{
    height: 65vh;
    overflow-y: auto;
  }
  .ParameterTableColumn{
    height: 78vh;
  }
  .ParameterTableColumn1{
    height: 70.3vh;
  }
  .RTMoptionalPerraTab{
    height: 52vh;
  }
  .ReportsdataDiv{
    height: 69.5vh;
  }
  .RTMcontainer{
    height: 82.5vh;
  }
  .rtmChartcontainer{
    height: 77.5vh;
  }
   }
  @media (min-height: 911px) and (max-height:951px) {
  .MainLayoutCss{
   height: 80vh !important;
  }
  .chartDiv{
    height: 64vh;
    overflow-y: auto;
  }
  .ParameterTableColumn{
    height: 77vh;
  }
  .ParameterTableColumn1{
    height: 69.3vh;
  }
  .RTMoptionalPerraTab{
    height: 52vh;
  }
  .ReportsdataDiv{
    height: 68.5vh;
  }
  .RTMcontainer{
    height: 81.6vh;
  }
  .rtmChartcontainer{
    height: 76.6vh;
  }
  }
  @media (min-height: 893px) and (max-height:910px) {
.MainLayoutCss{
 height: 79vh !important;
}
.chartDiv{
  height: 63.5vh;
  overflow-y: auto;
}
.ParameterTableColumn{
  height: 76vh;
}
.ParameterTableColumn1{
  height: 69.3vh;
}
.RTMoptionalPerraTab{
  height: 52vh;
}
.ReportsdataDiv{
  height: 67.7vh;
}
.RTMcontainer{
  height: 81vh;
}
.rtmChartcontainer{
  height: 76vh;
}
  }
/* 67 screen */
  @media (min-height: 851px) and (max-height:893px) {
  .MainLayoutCss{
   height: 78.5vh !important;
  }
  .chartDiv{
    height: 62vh;
    overflow-y: auto;
  }
  .ParameterTableColumn{
    height: 75vh;
  }
  .ParameterTableColumn1{
    height: 67.3vh;
  }
  .RTMoptionalPerraTab{
    height: 52vh;
  }
  .ReportsdataDiv{
    height: 66vh;
  }
  .RTMcontainer{
    height: 80.5vh;
  }
  .rtmChartcontainer{
    height: 75.5vh;
  }
  }
  @media (min-height: 811px) and (max-height:850px) {
  .MainLayoutCss{
   height: 77.5vh !important;
  }
  .chartDiv{
    height: 61vh;
    overflow-y: auto;
  }
  .ParameterTableColumn{
    height: 74vh;
  }
  .ParameterTableColumn1{
    height: 66.3vh;
  }
  .RTMoptionalPerraTab{
    height: 52vh;
  }
  .ReportsdataDiv{
    height: 64.5vh;
  }
  .RTMcontainer{
    height: 79.5vh;
  }
  .rtmChartcontainer{
    height: 74.5vh;
  }
  }
  @media (min-height: 794px) and (max-height:810px) {
.MainLayoutCss{
 height: 76.5vh !important;
}
.chartDiv{
  height: 60vh;
  overflow-y: auto;
}
.ParameterTableColumn{
  height: 73vh;
}
.ParameterTableColumn1{
  height: 63.3vh;
}
.RTMoptionalPerraTab{
  height: 52vh;
}
.ReportsdataDiv{
  height: 63.5vh;
}
.RTMcontainer{
  height: 79vh;
}
.rtmChartcontainer{
  height: 74vh;
}
  }
/* 75 screen */
  @media (min-height: 775px) and (max-height:794px) {
.MainLayoutCss{
 height: 76.2vh !important;
}
.chartDiv{
  height: 59.5vh;
  overflow-y: auto;
}
.ParameterTableColumn{
  height: 73vh;
}
.ParameterTableColumn1{
  height: 63.4vh;
}
.RTMoptionalPerraTab{
  height: 52vh;
}
.ReportsdataDiv{
  height: 62.7vh;
}
.RTMcontainer{
  height: 78vh;
}
.rtmChartcontainer{
  height: 73vh;
}
  }
/*  80 screen */
  @media (min-height: 741px) and (max-height:775px) {
  .MainLayoutCss{
   height: 75vh !important;
  }
  .chartDiv{
    height: 58vh;
    overflow-y: auto;
  }
  .ParameterTableColumn{
    height: 71vh;
  }
  .ParameterTableColumn1{
    height: 63.3vh;
  }
  .RTMoptionalPerraTab{
    height: 52vh;
  }
  .ReportsdataDiv{
    height: 61vh;
  }
  .RTMcontainer{
    height: 77vh;
  }
  .rtmChartcontainer{
    height: 72vh;
  }
  }
  @media (min-height: 700px) and (max-height:740px) {
  .MainLayoutCss{
   height: 74vh !important;
  }
  .chartDiv{
    height: 56.5vh;
    overflow-y: auto;
  }
  .ParameterTableColumn{
    height: 70vh;
  }
  .ParameterTableColumn1{
    height: 61.3vh;
  }
  .RTMoptionalPerraTab{
    height: 52vh;
  }
  .ReportsdataDiv{
    height: 58.5vh;
  }
  .RTMcontainer{
    height: 76vh;
  }
  .rtmChartcontainer{
    height: 71vh;
  }
  }
  @media (min-height: 661px) and (max-height:700px) {
.MainLayoutCss{
 height: 72.6vh !important;
}
.chartDiv{
  height: 54vh;
  overflow-y: auto;
}
.ParameterTableColumn{
  height: 68vh;
}
.ParameterTableColumn1{
  height: 58.3vh;
}
.RTMoptionalPerraTab{
  height: 52vh;
}
.ReportsdataDiv{
  height: 56vh;
}
.RTMcontainer{
  height: 75vh;
}
.rtmChartcontainer{
  height: 70vh;
}
  }
/* 90 screen */
  @media (min-height: 643px) and (max-height:661px) {
  .MainLayoutCss{
   height: 71.5vh !important;
  }
  .chartDiv{
    height: 53vh;
    overflow-y: auto;
  }
  .ParameterTableColumn{
    height: 66.5vh;
  }
  .ParameterTableColumn1{
    height: 56.8vh;
  }
  .RTMoptionalPerraTab{
    height: 52vh;
  }
  .ReportsdataDiv{
    height: 55vh;
  }
  .RTMcontainer{
    height: 74vh;
  }
  .rtmChartcontainer{
    height: 69vh;
  }
  }
  @media (min-height: 596px) and (max-height:643px) {
.MainLayoutCss{
 height: 69.5vh !important;
}
.chartDiv{
  height: 50.5vh;
  overflow-y: auto;
}
.ParameterTableColumn{
  height: 64vh;
}
.ParameterTableColumn1{
  height: 54.3vh;
}
.RTMoptionalPerraTab{
  height: 52vh;
}
.ReportsdataDiv{
  height: 52vh;
}
.RTMcontainer{
  height: 72vh;
}
.rtmChartcontainer{
  height: 67vh;
}
  }
/* 100 screen */
  @media (min-height: 563px) and (max-height:596px) {
  .MainLayoutCss{
    height: 67vh !important;
   } 
   .chartDiv{
    height: 48vh;
    overflow-y: auto;
  }
  .ParameterTableColumn{
    height: 62vh;
  }
  .ParameterTableColumn1{
    height: 52.4vh;
  }
  .RTMoptionalPerraTab{
    height: 52vh;
  }
  .ReportsdataDiv{
    height: 48vh;
  }
  .RTMcontainer{
    height: 70.5vh;
  }
  .rtmChartcontainer{
    height: 65.5vh;
  }
  }
  @media (min-height: 541px) and (max-height:563px) {
.MainLayoutCss{
 height: 66.5vh !important;
}
.chartDiv{
  height: 46.5vh;
  overflow-y: auto;
}
.ParameterTableColumn{
  height: 61vh;
}
.ParameterTableColumn1{
  height: 51.4vh;
}
.RTMoptionalPerraTab{
  height: 52vh;
}
.ReportsdataDiv{
  height: 46vh;
}
.RTMcontainer{
  height: 69vh;
}
.rtmChartcontainer{
  height: 64vh;
}
  }
/* 110 screen */
  @media (min-height: 476px) and (max-height:541px) {    
.MainLayoutCss{
 height: 62vh !important;
}
.chartDiv{
  height: 40.5vh;
  overflow-y: auto;
}
.ParameterTableColumn{
  height: 60vh;
}
.ParameterTableColumn1{
  height: 45.4vh;
}
.RTMoptionalPerraTab{
  height: 37vh;
}
.ReportsdataDiv{
  height: 39vh;
}
.RTMcontainer{
  height: 65vh;
}
.rtmChartcontainer{
  height: 60vh;
}
}

.VersionText{
font-size: 15px;
font-weight: bold;
margin-top: 4px;
}


/* Style the main container */
.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.content-container {
  flex-grow: 1;
  overflow-y: auto;
  /*background-color: cadetblue; */
}

Header {
  position: sticky;
  top: 0;
  z-index: 1;
}
Footer {
  position: sticky;
  bottom: 0;
  z-index: 1;
}
.ant-menu-horizontal {
  line-height: 32px;
  border-bottom: none;
}
