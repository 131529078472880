.folderstyle{
    font-size: 110px;
    color: #fdb900;
    cursor: pointer;
}
.foldernameStyle{
    margin-top: -10px;
    margin-left: -10px;
    font-weight: bold;
    cursor: pointer;
}
.filestyle{
    font-size: 110px;
    color: '#ff0000';
    cursor: pointer; 
}
.filestyleGreen{
    font-size: 110px;
    color: lightgreen;
    cursor: pointer; 
}
.backButton{
    border-radius: 50%;
    padding-left: 10px;
    cursor: pointer;
}
.BackErrowStyle{
    font-size: 25px;
    font-weight: bold;
}
.icon-hovered {
    transform: scale(1.2);
    font-size: 110px;
    color: #fdb900;
    cursor: pointer;
  }
.ResultTesxt{
margin:0px 4px ;
font-weight: bold;
color:#00867dff;
font-size: 16px;
}
.headingTesxt{
    margin:0px 4px ;
    font-weight: bold;
    font-size: 16px;
    margin-bottom:10px;
    }