.formInput {
    border-bottom: 1px solid #bfc0c2;
    font-size: 16px;
    font-weight: 600;
  }
  
  .formInputDiv {
    border-bottom: 1px solid #bfc0c2;
    background-color: white;
    margin-top: 15px;
  }
  .formInputLabel {
    font-size: 10px;
    padding: 8px 0px 0px 8px;
  }
  .ant-picker {
    border: none;
  }
  
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background-color: #005691;
    color: white;
  }
  .ant-btn:hover,
  .ant-tabs-tab:hover {
    /* color: #005691; */
    color: black;
    font-weight: bold;
  }
  
  /* .regestration .ant-col {
    height: 20px;
  } */
  .formInputStyle {
    background-color: #f5f5f5;
    padding: 2px 0px 0px 4px;
    border-bottom: 1px solid rgb(171, 170, 170);
  border-right: .5px solid rgb(171, 170, 170);
  height: 58px;
  margin-top: 20px;
  }
  
  .formLabelStyle {
    font-size: 16px;
    font-weight: 600;
  }
  .formInput {
    border-bottom: 1px solid #bfc0c2;
    font-size: 16px;
    font-weight: 600;
    width: calc(100% - 16px);
    background-color: #f5f5f5;
    border: none;
  }
  .formStyle {
    height: 515px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .previewFont {
    font-size: 16px;
    font-weight: bold;
  }
  .previewFontData {
    font-size: 16px;
    font-weight: bold;
    color:#005691;
    margin-left: 5px;
  }
