.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: none !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #005691;
  border-bottom: 1.5px soid #005691;
}

.ant-tabs-ink-bar {
  background: #005691;
}
.buttonStyle {
  margin: 12px;
}
.radioStyle {
  color: #005691;
  border-color: #005691;
}
.rowHeadingStyle {
  background-color: #005691;
  padding: 10px;
  margin-bottom: 20px;
  font-weight: 700;
  color: white;
}
.rowHeadingStyle11{
  background-color: #608fae;
  padding: 10px;
  margin-bottom: 20px;
  font-weight: 700;
  color: white;
}
.formStyle {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.ReportIcon {
  font-size: 28px;
  font-weight: 900;
  margin: 2px 5px;
  color: #005691;
}
.addButton {
  border: 1.5px solid #005691;
  background: none;
  color: #005691;
}
.saveButton {
  border: 1.5px solid #005691;
  background-color: #005691;
  color: #fff;
  margin-left: 15px;
}

.location .ant-input {
  border: none;
  border-bottom: 1px solid #bfc0c2;
  font-size: 16px;
}

.formInput {
  border-bottom: 1px solid #bfc0c2;
  font-size: 16px;
  font-weight: 600;
  width: calc(100% - 16px);
  background-color: #f5f5f5;
  border: none;
}

.formInputStyle {
  background-color: #f5f5f5;
  padding: 2px 0px 0px 4px;
  border-bottom: 1px solid rgb(171, 170, 170);
  border-right: .5px solid rgb(171, 170, 170);
  height: 58px;
}
.formInputStyleAlert{
  background-color: #f5f5f5;
  padding: 2px 0px 0px 4px;
  border-bottom: 1px solid rgb(171, 170, 170);
  border-right: .5px solid rgb(171, 170, 170);
  height: 58px;
  margin-bottom: 20px;
}

.formLabelStyle {
  font-size: 16px;

  font-weight: 600;
}
.ant-popover-title {
  height: 20px;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  padding: 2px;
  width: 50%;
  border: none;
}

.High {
  background-color: #ea4758;
  border-radius: 10px;
  color: white;
  text-align: center;
  /* width: 80px; */
}
.Medium {
  background-color: #caca57;
  border-radius: 10px;
  color: white;
  text-align: center;
  /* width: 80px; */
}
.Low {
  background-color: #58cf64;
  border-radius: 10px;
  color: white;
  text-align: center;
  /* width: 80px; */
}
.Trigger .ant-radio-button-wrapper > .ant-radio-button {
 
  background-color: rgb(0, 86, 145);
  color: white;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
 
  border-bottom: none;

  BACKGROUND-COLOR: #f5f5f5;
}
.SecondRowAlertTab{
  background-color: #a0d7ef;
  padding: 10px;
  margin: 10px 0px 10px;
  height: 50px;
}
.SecondoptionRow{
  background-color: #d9dbe0;
  padding: 10px;
  margin: 10px 0px 10px;
  height: 50px;
}


.filterCol{
  margin:0px 5px 0px 5px ;
}
.AlertTabModal{
  background-color: #f3f3f3;
  padding: 10px;
}
.AlertsubmitButton{
  margin-right: 10px;
  margin-top: 30px;
}
.ProprityTag{
  border-radius: 10px;
  width: 70px;
}
.ExportButton{
  float: right;
  background-color: #dfdfe0;
  border: none;
  margin-top: -38px;
  margin-right: 150px;

}
.CurrenttabfromDiv{
  height: c;
  margin-bottom: 20px;
  margin-top: -10px;
}
.EventModalCurrentab{
  height: 75vw;
  overflow-y: auto;
}
.confirmModalExc{
  text-align: center;
  font-size: 80px;
  color:#008ecf ;
}
.confirmYText{
  text-align: center;
  font-weight: bold;
}
