.ReportCard {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 10px;
}
.UperDiv {
  margin: 4px;
  display: flex;
  justify-content: space-between;
  
}
.UperDivButton {
  background-color: #e8e9eb;
  font-weight: bold;
  padding: 0px;
  border: none;
  color: #116ef0;
  margin-left: 5px;
}

.Parameters .ant-checkbox-group-item {
  display: flex;
  margin-top: 10px;
  font-size: 18px;
}
.ParameterColumn {
  font-size: 18px;
  padding: 10px;
}
.ParameterRow {
  background-color: #e8e9eb;
  
}
.paratabIcon {
  font-size: 20px;
  margin: 10px;
}
.MainTable .ant-table-tbody > tr > td {
  padding: 0px;
}
.MainTable tr:nth-child(odd) {
  background: #ffffff;
}
.MainTable tr:nth-child(even) {
  background: #e8e8e8;
}
.MainTable .ant-table-thead > tr > th{

    background: 	#D3D3D3;
    padding: 5px;
}
.bigBlueIcons {
  color: #4472b3;
  font-size: 40px;
  margin-right: 5px;
}
.bigBlueIconsText {
  color: #4472b3;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
}
.SmallIconsData {
  font-size: 15px;
  margin: 10px;
}
.parametersIconsData {
  /* background-color: #d4d5d8; */
  display: flex;
  justify-content: right;
  /* margin-top: -50px; */
}

.reportAnalysis .ant-segmented-item-selected {
  background-color: #005691;
  border-radius: 2px;
  /* box-shadow: 0 2px 8px -2px rgb(0 0 0 / 5%), 0 1px 4px -1px rgb(160 33 33 / 7%), 0 0 1px 0 rgb(0 0 0 / 8%); */
  color: white;
  font-size: 16px;
  font-weight: 600;
}
.ant-segmented-item {
  background-color: #56c0ee;
}

.selectStyle .ant-select.ant-select-in-form-item {
  width: 150% !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  border-radius: 2px;
  border-bottom: 1px solid #d9d9d9;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 16px;
}

.toggleParametersIdle {
  padding: 10px;
  border: 1px solid #005691;
  margin: 5px 2px;
  color: #005691;

  background: #efeff0;
}
.toggleParametersactive {
  padding: 10px;
  border: 1px solid #005691;
  margin: 5px 2px;
  color: white;
  background-color: #005691;
}

.saveButton {
  border: 1.5px solid #005691;
  background-color: #005691;
  color: #fff;
  margin-right: 16px;
}
.ant-modal-title {
  font-size: 22px;
  color: #000;
  font-weight: 600;
}
