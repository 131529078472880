.previewModalText {
  font-size: 16px;
  font-weight: bold;
  margin: 5px 0px;
}
.previewModalresponce {
  font-size: 16px;
  font-weight: bold;
  color: #00867dff;
  margin: 5px 0px;
}
.previewModalresponceTYPE {
  font-size: 18px;
  margin: 10px 0px;
  font-weight: bold;
  color: black;
}
.configurationEditIcon{
    color: black;
}
.configurationEditIcon:hover{
    color: #00867dff;
}