.EnergyCard {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  height: 300px;
}
.EnergyCard2 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background-color: #34e073;
}
.CardDiv {
  display: flex;
  justify-content: space-between;
}
.card-3 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  height: 330px;
}
.spectrum-background {
  background: linear-gradient(to top left, lime, transparent),
    linear-gradient(to top right, blue, transparent);
  background-blend-mode: screen;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 26px;
}
.ElevatorCard {
  position: relative;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 5px;
  /* width: 320px; */
  padding: 10px 0px;
}
.ElevatorCard2 {
  position: relative;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 5px;
  padding: 10px 0px;
  margin-right: 10px;
  height: 200px;
  margin-top: 10px;
}
.CardHeadr {
  font-size: 12px;
}
.CardAnswere {
  font-size: 15px;
}
.card-6 {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.22);
}
.tabElev {
  font-size: 13px;
  height: 12px;
}
.SemiChart {
 
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.22);
}
.tab .ant-table-tbody > tr > td {
  padding: 5px 1px !important;
}
.tab .ant-table-thead > tr > th {
  padding: 1px 1px !important;
}

.elevatorCardsStyle{
  text-align: center;
  align-items: center;
  border-bottom: 1px solid #f4f4f4;
  border-right: 1px solid #f4f4f4;
  display: block;
  margin: 10px 0;
}
.realtime .ant-segmented-item-selected {
  background-color: #005691;
  border-radius: 2px;
  /* box-shadow: 0 2px 8px -2px rgb(0 0 0 / 5%), 0 1px 4px -1px rgb(160 33 33 / 7%), 0 0 1px 0 rgb(0 0 0 / 8%); */
  color: #f2e30b;
  /* font-size: 16px; */
  font-weight: 600;
}
.ant-segmented-item {
  background-color: #e6eaec;
  height: 30px;
}
.ant-segmented {
  /* font-size: 16px; */
  font-weight: 600;
  
}
.ChartHeader {
  font-size: 16px;
  font-weight: 600;
  color: #005691;
}

.segmentStyle {
  background-color:#a0d7ef;
  padding: 10px;

  /* display: flex;
  justify-content: space-between; */
}
.fontStyle {
  font-size: 14px;
  font-weight: 600;
  color: #005691;
}
.apexcharts-menu-icon{
  display: none !important;
}

/* Styling for the main container */
.RTMcontainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Styling for the form container */
.form-container {
  padding: 10px 10px 0;
  background-color: #a0d7ef;
  margin: 0 0 10px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-container {
  flex-grow: 1;
  overflow-y: auto;
}

.card-row {
  display: flex;
  flex-wrap: wrap;
}
