.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: white;
}

body,
html {
  font-family: "bosch-Light";
}

@font-face {
  font-family: "bosch-Light";
  src: local("bosch-Light"),
    url(./assets/fonts/bosch-txt/BoschSans-Light.woff) format("woff"),
    url(./assets/fonts/bosch-txt/BoschSans-Light.woff2) format("woff2");
}
@font-face {
  font-family: "bosch-Medium";
  src: url(./assets/fonts/bosch-txt/BoschSans-Medium.woff) format("woff"),
    url(./assets/fonts/bosch-txt/BoschSans-Medium.woff2) format("woff2");
}
@font-face {
  font-family: "bosch-Regular";
  src: url(./assets/fonts/bosch-txt/BoschSans-Regular.woff) format("woff"),
    url(./assets/fonts/bosch-txt/BoschSans-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "bosch-Bold";
  src: url(./assets/fonts/bosch-txt/BoschSans-Bold.woff) format("woff"),
    url(./assets/fonts/bosch-txt/BoschSans-Bold.woff2) format("woff2");
}

@font-face {
  font-family: "bosch-sansreg";
  src: url(./assets/fonts/bosch-txt/BoschSans-Regular.woff) format("woff"),
    url(./assets/fonts/bosch-txt/BoschSans-Regular.woff2) format("woff2");
}
.backgroundStyle .ant-select:not(.ant-select-customize-input) .ant-select-selector,.ant-picker,.ant-checkbox-inner {
  background-color: #f5f5f5;}
.ant-checkbox-checked .ant-checkbox-inner{
    background-color: #005691;
    border-color: #005691;
  }


  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }
  
  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }
  
  .ant-picker-panels > *:last-child {
    display: none;
  }
  
  .ant-picker-panel-container, .ant-picker-footer {
    width: 280px !important;
  }
  
  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important; 
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap{
    height: 40px;
  }


  
