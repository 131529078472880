.formInput1111 {
  border-bottom: 1px solid #bfc0c2;
  font-size: 16px;
  font-weight: 600;
  background-color: #e8f0fe;
}

.formInputDiv11 {
  border-bottom: 1px solid #bfc0c2;
  background-color: #e8f0fe;
  margin-top: 15px;
}
.formInputLabel {
  font-size: 10px;
  padding: 8px 0px 0px 8px;
}
.ant-picker {
  border: none;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #005691;
  color: white;
}
.ant-btn:hover,
.ant-tabs-tab:hover {
  color: #005691;
}

/* .regestration .ant-col {
  height: 20px;
} */
.formInputStyle {
  background-color: #f5f5f5;
  padding: 2px 0px 0px 4px;
  border-bottom: 1px solid rgb(171, 170, 170);
  border-right: .5px solid rgb(171, 170, 170);
  height: 58px;
}

.formLabelStyle {
  font-size: 16px;
  font-weight: 600;
}
.formInput {
  border-bottom: 1px solid #bfc0c2;
  font-size: 16px;
  font-weight: 600;
  width: calc(100% - 16px);
  background-color: #f5f5f5;
  border: none;
}

.hh1 {
  text-align: center;
  padding: 20px 20px 10px 20px;
  color: black;
}

.hh2 {
  text-align: center;
  padding-bottom: 10px;
}

.MsoNormal {
  margin-bottom: 0in;
  line-height: normal;
}
.MsoListParagraphCxSpFirst {
  line-height: normal;
}

.MsoListParagraphCxSpMiddle {
  line-height: 115%;
}

.Calibri {
  text-align: left;
  line-height: 115%;
}

.span11 {
  font-size: 9pt;
  line-height: 115%;
  font-family: "Bosch Office Sans";
}

.span22 {
  font-size: 9pt;
  font-family: "Bosch Office Sans";
}
.span33 {
  font-weight: bold;
  font-family: "Bosch Office Sans";
}
.span44 {
  font: 7pt "Times New Roman";
}
.span55 {
  font-size: 9pt;
  font-family: "Bosch Office Sans";
}

.span66 {
  font-size: 9pt;
  line-height: 115%;
  font-family: "Bosch Office Sans";
}

.span77 {
  margin: 0in;
  text-align: left;
  line-height: 115%;
}
.MsoListParagraph {
  margin-left: 0.25in;
  text-indent: -0.25in;
  line-height: normal;
}

.MsoListParagraphCxSpMiddle {
  line-height: normal;
}

.p11 {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 8pt;
  margin-left: 0in;
  line-height: normal;
  font-size: 15px;
  font-family: "Calibri", sans-serif;
  text-align: justify;
}

.spanb1 {
  color: green;
  font-family: "Bosch Sans Medium", sans-serif;
}

.p22 {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 8pt;
  margin-left: 0in;
  line-height: normal;
  font-size: 15px;
  font-family: "Calibri", sans-serif;
  text-align: justify;
}

.yes:hover {
  color: white;
}

@media (min-width: 1920px) {
  .loginscreen {
    background-size: cover !important;
  }
}

.formInput1111.ant-input-affix-wrapper > .ant-input:not(textarea) {
  padding: 0;
  background: #e8f0fe;
}

.formInput1111.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  /* background: #fff; */
  border-color: #ff4d4f;
}
.formInput1111.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  /* background: #fff; */
  border-color: #ff4d4f;
}

.btn-grad {
  background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA  51%, #1FA2FF  100%) !important;
  transition: 0.5s !important;
  background-size: 200% auto !important;
  color: white !important;
  box-shadow: 0 0 20px #eee !important;
  display: block !important;
}

.btn-grad:active,
.btn-grad:focus,
.btn-grad:hover {
  transition: 0.5s !important;
  background-position: right center !important;
  text-decoration: none !important;
}

.ant-avatar{
  background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA  99%, #1FA2FF  100%) !important;
}

.btn-grad[disabled], .btn-grad[disabled]:hover, .btn-grad[disabled]:focus, .btn-grad[disabled]:active {
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
  text-shadow: none !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
}