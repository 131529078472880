.headerRow {
  width: 100%;
  position: relative;
  background: linear-gradient(145deg, #f4808b 0%, #dc80ba 70%, #7c5a9f 100%);
  padding: 10px 50px 0px 50px;
}
.divStyle {
  background-color: #dfdfe0;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
}
.circleStyle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1.5px solid #fff;
  display: block;
  font-size: 28px;
  text-align: center;
  color: #fff;
  margin: 12 auto;
}
.circleStyle img {
  width: 50%;
  height: 50%;
  position: relative;
  top: 50%;
  transform: translate(0%, -50%);
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.buttonStyle {
  width: 150px;
  color: #005691;
  border: 1.5px solid #005691;
}
.labelStyle {
  font-size: 12px;
  width: 85px;
  color: #fff;
  display: block;
  padding-top: 3vw;
}
.labelStyle1{
  font-size: 12px;
  width: 85px;
  color: #fff;
  display: block;
  padding-top: 1vw;
}
.lineStyle {
  content: "";
  width: 10vw;
  display: inline-block;
  position: absolute;
  left: 22vw;
  top: 31px;
  border-bottom: 1.5px solid #fff;
}

@media (max-width: 768px) and (min-width: 300px) {
  .lineStyle {
    width: 10vw;
    left: 14vw;
    top: 31px;
  }
  .labelStyle {
    padding-top: 3vw;
  }
}

@media (max-width: 992px) and (min-width: 769px) {
  .lineStyle {
    width: 3.4vw;
    left: 7.4vw;
    top: 31px;
  }
}
@media (min-width: 993px) {
  .lineStyle {
    width: 4vw;
    left: 6vw;
    top: 31px;
  }
  .labelStyle {
    padding-top: 2.6vw;
  }
}

.site-page-header {
  padding: 0px;
}
.SpaceOverviewTab .ant-tabs-tab {
  font-size: 16px;
  font-weight: 600;
}
.circleStyle img {
  width: 50%;
  height: 50%;
  position: relative;
  top: 50%;
  transform: translate(0%, -50%);
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.buttonStyle {
  width: 150px;
  color: #005691;
  border-color: #005691;
}
.labelStyle {
  font-size: 12px;
  width: 85px;
  color: #fff;
  display: block;
  padding-top: 3.6vw;
}
.lineStyle {
  content: "";
  width: 10vw;
  display: inline-block;
  position: absolute;
  left: 22vw;
  top: 31px;
  border-bottom: 1.5px solid #fff;
}

@media (max-width: 768px) and (min-width: 300px) {
  .lineStyle {
    width: 10vw;
    left: 14vw;
    top: 31px;
  }
  .labelStyle {
    padding-top: 5.2vw;
  }
}

@media (max-width: 992px) and (min-width: 769px) {
  .lineStyle {
    width: 3.4vw;
    left: 7.4vw;
    top: 31px;
  }
}
@media (min-width: 993px) {
  .lineStyle {
    width: 4vw;
    left: 6vw;
    top: 31px;
  }
  .labelStyle {
    padding-top: 2.6vw;
  }
}

.site-page-header {
  padding: 0px;
}
.SpaceOverviewTab .ant-tabs-tab {
  font-size: 16px;
  font-weight: 600;
}
.boschInputouterDiv {
  border-bottom: 1px solid #bfc0c2;
  background-color: white;
  margin-top: 15px;
  padding-left: 5px;
}
.boschInputouterlabel {
  font-size: 10px;
  font-weight: 600;
  padding: 8px 0px 0px 8px;
}
.boschInputInnerDiv {
  border: none;
  border-bottom: 1px solid #bfc0c2;
  font-size: 16px;
  font-weight: 500;
  width: calc(100% - 16px);
  background-color: white;
  margin-left :10px;
  
}
.addButton {
  border: 1.5px solid #005691;
  color: #005691;
}
.saveButton {
  border: 1.5px solid #005691;
  background-color: #005691;
  color: #fff;
  margin-left: 15px;
}
.addElevatorButton {
  border: 1.5px solid #005691;
  background-color: #005691;
  color: #fff;
 
}

.formInput {
  /* border-bottom: 1px solid #bfc0c2; */
  font-size: 16px;
  font-weight: 600;
  width: calc(100% - 16px);
}

.formInputStyleLocation {
  background-color: #f5f5f5;
  padding: 2px 0px 0px 4px;
  border-bottom: 1px solid rgb(171, 170, 170);
  border-right: .5px solid rgb(171, 170, 170);
  height: 58px;
}
.formLabelStyle{
  font-size: 16px;
  font-weight: 600;
}
.formInputLabel {
  font-size: 10px;
  padding: 8px 0px 0px 8px;
}

.addoperating-btn {
  /* width: 150px; */
  color: #005691;
  border: 2px solid #005691;
  height: 50px;
  padding: 15px;
}

.save-cont-btn {
  width: 150px;
  color: white;
  border-color: #005691;
  height: 50px;
  background-color: #005691;
}

.opt-btn {
  outline: 0;
  border-width: 0 0 1px;
  border-color: black;
}

Input {
  outline: 0;
  border-width: 0 0 1px;
  border-color: black;
}
.Input:focus {
  outline: none;
}

.addpeak-btn {
  width: 180px;
  color: #005691;
  border-color: #005691;
  height: 50px;
}
.container {
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  margin-top: 10px;
}
.container .btn-holder {
  justify-content: flex-end;
  display: flex;
}
.cancelpeak-btn {
  width: 100px;
  color: #005691;
  border-color: #005691;
  height: 50px;
}

.save-btn {
  width: 100px;
  color: white;
  border-color: #005691;
  height: 50px;
  background-color: #005691;
}

.location .ant-col-24.ant-form-item-label {
  padding: 0;
}
.location .ant-input {
  border: none;
  border-bottom: 1px solid #bfc0c2;
  font-size: 16px;
}
.location
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fafcfe;
  background: #005691;
  border-color: #005691;
}
.location .ant-radio-button-wrapper {
  border: 1px solid #005691;
}
.location .ant-input-number-input {
  border: 1px solid #005691;
  font-size: 16px;
}
/* .location .ant-btn {
  border: 1px solid #005691;
  font-size: 16px;
  background-color: #005691;
  color: white;
} */
/* .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
} */
.location .ant-form label {
  font-size: 18px;
}

/* /* .ant-layout{
  background-color: #ffffff !important;
} */
.formInputDiv {
  /* border-bottom: 1px solid #a0a1a1; */
  /* background-color: #f5f5f5; */
  margin-top: 15px;
}
.formInputLabel {
  font-size: 10px;
  padding: 8px 0px 0px 8px;
}
.formInput {
  border: none;
  border-bottom: 1px solid #bfc0c2;
  font-size: 16px;
  font-weight: 600;
  width: calc(100% - 16px);
  background-color: #f5f5f5;
}
.formInputDivElevatorDetails {
  border-bottom: 1px solid #d3d5d8;
  background-color: #d3d5d8;
  margin-top: 15px;
  border-radius: 2px;
}
.formInputElevatorDetails {
  border-bottom: 1px solid #d3d5d8;
  font-size: 16px;
  font-weight: 600;
  width: calc(100% - 16px);
  background-color: #d3d5d8;
  border: none;
  border-radius: 2px;
}


.spectrum-background_Location {
  background-color: #d9dbe0;
  background-blend-mode: screen;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 40px;
  padding: 5px 20px;
}

.spectrum-background_LocationCard {
  background-color: #d9dbe0;
  background-blend-mode: screen;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 44px;
  padding: 5px 20px;
}
.LocationCard{
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 5px;
  background-color: #fbfcfc;
}
.LocationStoreName{
  font-size: 20px;
  font-weight: bolder;
  color: rgb(72, 71, 71);
}
.LocationInternalData{
  font-size: 16px;
  font-weight: bold;
  margin-top: 12px;
}
.LocationInternalData1{
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
}
.elevatorheadingText{
  font-size: 16px;
  /* font-weight: bold; */
}
.elevatorheading{
  font-size: 18px;
  font-weight: bold;
}
.formInputStyle {
  background-color: #d9dbe0;
  padding: 2px 0px 0px 4px;
  border-bottom: 1px solid rgb(171, 170, 170);
  border-right: .5px solid rgb(171, 170, 170);
  height: 58px;
}

.formLabelStyle {
  font-size: 16px;
  font-weight: 600;
}
.formInput101 {
  border-bottom: 1px solid #d9dbe0;
  font-size: 16px;
  /* font-weight: 600; */
  width: calc(100% - 16px);
  background-color: #f5f5f5;
  border: none;
}
.formStyle {
  height: 515px;
  overflow-y: auto;
  overflow-x: hidden;
}
.formInputStyleButton{
  background-color: #707070;
  padding: 2px 15px;
  height: 58px;
  margin-top: 20px;
  color: white;
}
.formInputStyleButton:focus{
  background-color: #005691;
}
.formInputStyleButtonLocation{
  background-color: #707070;
  padding: 2px 15px;
  height: 55px;
  /* margin-top: 20px; */
  color: white;
}
.formInputStyleButtonLocation:focus{
  background-color: #005691;
}

@media (min-height: 450px) {
  .phantomDeviceSpace{
    height: 30vh;
    overflow-y: auto;
    padding: 10px;
    
  }
  .phantomDeviceSpace1{
    height: 29vh;
    overflow-y: auto;
    padding: 10px;
    
  }
}

@media (max-height: 850px) and (min-height: 750px) {
  .phantomDeviceSpace{
    height: 42.5vh;
    overflow-y: auto;
    padding: 10px;
    
  }
  .phantomDeviceSpace1{
    height: 41.5vh;
    overflow-y: auto;
    padding: 10px;
    
  }
}
@media (max-height: 1050px) and (min-height: 850px) {
  .phantomDeviceSpace{
    height: 50vh;
    overflow-y: auto;
    padding: 10px;
    
  }
  .phantomDeviceSpace1{
    height: 54vh;
    overflow-y: auto;
    padding: 10px;
    
  }
}
.parentbuttonDiv{
  display: flex;
  justify-content: end;
  
}
.parentButton{
  color: white;
  margin:10px 10px ;
  height:40px;
}
.parentColapse{
  background-color: #d9dbe0;
  border-radius: 10px;
  margin: 10px;
}
.parentEdit{
  font-size: 23px;
  margin: 2px 15px;
}
.parentDelete{
  font-size: 23px;
  margin: 2px 15px;
}
.parentpanelDiv{
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}
.parentLoader{
  margin-top: 200px;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}
.parentContain{
  display: flex;
  font-size: 14px;
}
.parentHeaderContain{
  width: 120px;
  font-weight: 600;
}
.rowHeadingStyle11{
  background-color: #608fae;
  padding: 10px;
  margin-bottom: 20px;
  font-weight: 700;
  color: white;
}
.parentFormLoader{
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}


